<template>
  <LayoutVisiteur>
    <section ref="top">
      <div class="container-fluid">
        <div class="row g-4 p-2 py-4">
          <div class="col-lg-3 col-12 container-fluid">
            <div
              class="border-0 bg-dark text-light rounded p-3 shadow sticky-top"
              style="top: 5.6rem; z-index: 0; min-height: 25.8rem"
            >
              <h4>Nos accompagnements</h4>
              <hr />
              <div
                class="nav flex-column nav-pills me-3 w-100"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <button
                  v-for="service in services"
                  :key="service.id_service"
                  class="nav-link text-start"
                  :id="`v-pills-${service.slug}-tab`"
                  data-bs-toggle="pill"
                  :class="serviceSelected === service.slug && 'active'"
                  :data-bs-target="`#v-pills-${service.slug}`"
                  type="button"
                  role="tab"
                  :aria-controls="`v-pills-${service.slug}`"
                  aria-selected="true"
                  @click="scroll"
                >
                  {{ service.title_service }}
                </button>
              </div>
              <!-- <hr /> -->
            </div>
          </div>
          <div class="col-lg-9 col-12" ref="DescriptionService">
            <div class="p-3 border-0 bg-light text-dark rounded h-100">
              <DetailService />
            </div>
          </div>
        </div>
      </div>
    </section>
  </LayoutVisiteur>
</template>

<script>
import LayoutVisiteur from "../../layout/LayoutVisiteur.vue";
import DetailService from "../../components/visiteur/DetailService.vue";
import { getAllServicesWithoutContent } from "../../api/serviceAdmin";

export default {
  name: "ServiceVisiteur",
  components: {
    LayoutVisiteur,
    DetailService,
  },
  computed: {
    serviceSelected() {
      return this.$route.params.id_services;
    },
  },
  data() {
    return {
      services: [],
    };
  },
  mounted() {
    getAllServicesWithoutContent().then((result) => {
      this.services = result.data;
    });
  },
  methods: {
    scroll() {
      if (window.innerWidth < 991) {
        this.$refs.DescriptionService.scrollIntoView({ behavior: "smooth" });
      } else {
        window.scrollTo(0, 0);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-service {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  // display: flex;
  // align-items: center;
  // justify-content: center;

  button {
    transition: 500ms;
    transform: rotate(180deg);

    &:hover {
      transform: scale(1.2) rotate(0deg);
    }
  }

  // button {
  //   transition: 500ms;
  //   // position: absolute;

  //   &:first-child {
  //     visibility: hidden;
  //     opacity: 0;
  //   }

  //   &:last-child {
  //     visibility: visible;
  //     opacity: 1;
  //   }
  // }

  // &:hover {
  //   button {
  //     &:last-child {
  //       visibility: hidden;
  //       opacity: 0;
  //     }

  //     &:first-child {
  //       visibility: visible;
  //       opacity: 1;
  //     }
  //   }
  // }
}
</style>
