<template>
  <section class="scroll-mobile">
    <div>
      <div
        class="d-flex align-items-start tab-content w-100"
        id="v-pills-tabContent"
      >
        <div
          v-for="service in services"
          :key="service.id_service"
          class="tab-pane fade show w-100"
          :class="serviceSelected === service.slug && 'active'"
          role="tabpanel"
          :id="`v-pills-${service.slug}`"
          :aria-labelledby="`v-pills-${service.slug}-tab`"
        >
          <h2 class="text-start text-primary text-center">
            {{ service.title_service }}
          </h2>
          <hr />
          <div class="w-100">
            <div v-html="service.content_service"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-service">
      <button
        class="btn btn-primary rounded-pill d-none"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#modalService"
      >
        <div class="d-flex gap-2 px-2 align-items-center">
          <span>Faire le premier pas</span>
          <span><IcoRejoindre /></span>
        </div>
      </button>
      <button
        class="btn btn-primary p-3 d-flex align-items-center justify-content-center"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#modalService"
        style="border-radius: 8px"
        v-if="services.length"
      >
        <span class="px-3">Premier pas</span>
        <IcoRejoindre />
      </button>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="modalService"
      tabindex="-1"
      aria-labelledby="modalLabelService"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content border-0 bg-light text-dark">
          <div class="modal-header mx-2">
            <h4 class="modal-title text-primary" id="modalLabelService">
              Demander un service en particulier
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeModalService"
            ></button>
          </div>

          <div class="modal-body">
            <form
              class="row p-2"
              @submit.prevent="confirm"
              id="formConfirm"
              formnovalidate="formnovalidate"
            >
              <div class="col-md-6 mb-3">
                <label for="inputName" class="form-label">Nom complet*</label>
                <input
                  type="text"
                  class="form-control"
                  id="inputName"
                  v-model="form.fname_candidat_service"
                  :class="{
                    'is-invalid':
                      submitted && $v.form.fname_candidat_service.$error,
                  }"
                />
                <div
                  v-if="submitted && !$v.form.fname_candidat_service.required"
                  class="invalid-feedback"
                >
                  Veuillez insérer votre nom complet
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="inputTel" class="form-label">Téléphone*</label>
                <input
                  type="text"
                  class="form-control"
                  id="inputTel"
                  v-model="form.tel_candidat_service"
                  :class="{
                    'is-invalid':
                      submitted && $v.form.tel_candidat_service.$error,
                  }"
                />
                <div
                  v-if="submitted && !$v.form.tel_candidat_service.required"
                  class="invalid-feedback"
                >
                  Veuillez insérer votre numéro de téléphone
                </div>
              </div>
              <div class="col-12 mb-3">
                <label for="inputMail1" class="form-label">Mail*</label>
                <input
                  type="email"
                  class="form-control"
                  id="inputMail1"
                  v-model="form.email_candidat_service"
                  :class="{
                    'is-invalid':
                      submitted && $v.form.email_candidat_service.$error,
                  }"
                />
                <div
                  v-if="submitted && $v.form.email_candidat_service.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.form.email_candidat_service.required"
                    >Veuillez insérer votre adresse mail</span
                  >
                  <span v-if="!$v.form.email_candidat_service.email"
                    >Veuillez insérer une adresse mail valide</span
                  >
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="inputTypeService" class="form-label"
                  >Type de service*</label
                >
                <select
                  class="form-select"
                  id="inputTypeService"
                  v-model="form.type_service"
                  :class="{
                    'is-invalid': submitted && $v.form.type_service.$error,
                  }"
                >
                  <option selected class="d-none"></option>
                  <option value="Audit">Audit</option>
                  <option value="Formation">Formation</option>
                  <option value="Conseils">Conseils</option>
                  <option value="Accompagnement">Accompagnement</option>
                  <option value="Coaching">Coaching</option>
                  <option value="Refonte">Refonte</option>
                  <option value="Marketing">Marketing</option>
                  <option value="Ressources Humaines">
                    Ressources Humaines
                  </option>
                </select>
                <div
                  v-if="submitted && !$v.form.type_service.required"
                  class="invalid-feedback"
                >
                  Veuillez séléctionner le type de service
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="inputTypeClient" class="form-label"
                  >Type de client*</label
                >
                <select
                  class="form-select"
                  id="inputTypeClient"
                  v-model="form.type_candidat_service"
                  :class="{
                    'is-invalid':
                      submitted && $v.form.type_candidat_service.$error,
                  }"
                >
                  <option selected class="d-none"></option>
                  <option value="Particulier">Particulier</option>
                  <option value="Société">Société</option>
                </select>
                <div
                  v-if="submitted && !$v.form.type_candidat_service.required"
                  class="invalid-feedback"
                >
                  Veuillez séléctionner le type de candidat
                </div>
              </div>
              <div class="col-12">
                <label for="inputMessage" class="form-label">Message</label>
                <textarea
                  class="form-control"
                  id="inputMessage"
                  rows="4"
                  style="resize: none"
                  v-model="form.message_candidat_service"
                ></textarea>
              </div>
              <div class="mt-2">
                <input
                  type="checkbox"
                  class="form-check-input me-2"
                  required
                  v-model="consent"
                />
                <span
                  >J'ai bien lu et approuvé les Mentions Légales du site</span
                >
              </div>
              <div class="mt-2">
                <input
                  type="checkbox"
                  class="form-check-input me-2"
                  required
                  v-model="cgv"
                />
                <span
                  >J'accepte sans réserve les Conditions Générales de
                  MK-co</span
                >
              </div>
            </form>
          </div>
          <div class="modal-footer mx-2">
            <button
              class="btn btn-secondary me-0"
              data-bs-dismiss="modal"
              ref="close"
              @click="closeModalService"
            >
              Annuler
            </button>
            <button
              v-if="loading"
              class="btn btn-primary"
              type="button"
              disabled
            >
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Envoi...
            </button>
            <button
              v-else
              type="submit"
              form="formConfirm"
              class="btn btn-primary ms-3"
              formnovalidate="formnovalidate"
            >
              Envoyer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
  </section>
</template>

<script>
import { getAllServices } from "../../api/serviceAdmin";
import { success, error } from "../../utils/toast";
import { addService } from "../../api/service";
import { required, email } from "vuelidate/lib/validators";
import { socket } from "../../api/socket";
import IcoRejoindre from "vue-material-design-icons/ShoePrint.vue";

export default {
  name: "DetailService",
  components: { IcoRejoindre },
  computed: {
    serviceSelected() {
      return this.$route.params.id_services;
    },
  },
  data() {
    return {
      services: [],
      form: {
        fname_candidat_service: "",
        email_candidat_service: "",
        type_candidat_service: "",
        tel_candidat_service: "",
        type_service: "",
        message_candidat_service: "",
      },
      loading: false,
      submitted: false,
      consent: false,
      cgv: false,
    };
  },
  validations: {
    form: {
      type_candidat_service: { required },
      fname_candidat_service: { required },
      email_candidat_service: { required, email },
      tel_candidat_service: { required },
      type_service: { required },
    },
  },
  methods: {
    closeModalService() {
      this.submitted = false;
    },
    confirm() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.consent && this.cgv) {
        this.loading = true;
        addService(this.form).then((result) => {
          //console.log("call");
          if (result.data.error) {
            error(result.data.error);
          } else {
            socket.emit("send-notif", {
              data: result.data,
              type: "SERVICE",
              name: this.form.fname_candidat_service,
              service: this.form.type_service,
            });
            this.form.fname_candidat_service = "";
            this.form.email_candidat_service = "";
            this.form.type_candidat_service = "";
            this.form.tel_candidat_service = "";
            this.form.type_service = "";
            this.form.message_candidat_service = "";
            this.loading = false;
            this.$refs.close.click();
            success("Candidature envoyé avec succès");
          }
        });
      } else {
        error("Veuillez accepter les consentements");
      }
    },
  },
  mounted() {
    getAllServices().then((result) => {
      this.services = result.data;
    });
  },
};
</script>

<style lang="scss" scoped>
.btn-service {
  display: flex;
  justify-content: center;
}
@media (max-width: 991px) {
  .scroll-mobile {
    min-height: 100vh;
  }
}
</style>
